'use client';
import Jpg128 from '@/ui/client/img/Jpg128';

const LunnetteImg = () => {
  return (
    <Jpg128
      jpgSrc='/img/services/enlevements-voitures.jpg'
      webpSrc='/img/services/enlevements-voitures.webp'
      altText='Débarrasser une épave de voiture gratuitement'
    />
  );
};

export default LunnetteImg;
