// src/assets/img/services/DeflectorImg.jsx
'use client';
import Jpg128 from '@/client/img/Jpg128';

const DeflectorImg = () => {
  return (
    <Jpg128 jpgSrc='/img/services/jetez-voiture.jpg' webpSrc='/img/services/jetez-voiture.webp' altText='Jeter votre voiture, auto ou épaves gratuitement' />
  );
};

export default DeflectorImg;
