// src/ui/client/img/JpgWebp.jsx
'use client';
import useSelectImg from '@/hook/useSelectImg';
import Image from 'next/image';

const Jpg128 = ({ jpgSrc, webpSrc, altText, width = 256, height = 256, className = '' }) => {
  const selectedImage = useSelectImg(jpgSrc, webpSrc, undefined, jpgSrc);

  return (
    <div className='img-wrap'>
      <Image src={selectedImage} alt={altText} width={width} height={height} priority className='img' />
    </div>
  );
};

export default Jpg128;
