// src/asset/img/services/LateralImg.jsx
'use client';
import Jpg128 from '@/ui/client/img/Jpg128';

const LateralImg = () => {
  return (
    <Jpg128
      jpgSrc='/img/services/depanne-express.jpg'
      webpSrc='/img/services/depanne-express.webp'
      altText='Dépannage express toutes marques de voitures, auto'
    />
  );
};

export default LateralImg;
